<template>
  <div>
    <v-dialog v-model="singleLoading" hide-overlay persistent width="300">
      <v-card outlined class="pa-5 rounded20" color="teal" dark>
        <v-card-text>
          Cargando receta...
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="!singleLoading && recipe"
      v-model="dialog"
      width="80vw"
      persistent
      v-on:click:outside="(dialog = false), $emit('clearrecipe')"
      scrollable
    >
      <v-card class="pa-10 rounded20">
        <v-card-text align="center">
          <v-chip color="teal lighten-1" outlined dark label>
            <span class="overline"
              >{{ recipe.id }} - {{ recipe.pharmacyCode }}</span
            >
          </v-chip>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-list dense>
                <v-list-item
                  v-for="propety in recipeProperties"
                  :key="propety.key"
                >
                  <v-list-item-avatar>
                    <v-icon size="20">
                      {{ propety.icon }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ propety.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ recipe[propety.key] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-list v-if="recipe.doctor" dense>
                <v-list-item
                  v-for="propety in doctorProperties"
                  :key="propety.key"
                >
                  <v-list-item-avatar>
                    <v-icon size="20">
                      {{ propety.icon }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ propety.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ recipe.doctor[propety.key] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list-item
                v-else
              >
                <v-list-item-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Matrícula Médico
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ recipe.doctorId }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="item in properties"
                    :key="item.key"
                    class="text-center"
                  >
                    {{ item.title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!singleLoading">
                  <template v-if="recipe.meds.length">
                    <tr
                      v-for="(row, index) in recipe.meds"
                      :class="{
                        strikeout: row.nulled == 1,
                        'text-center': true,
                      }"
                      :key="index"
                    >
                      <td v-for="item in properties" :key="item.key">
                        {{ item.prepend }} {{ row[item.key] }}
                      </td>
                      <td>
                        <v-btn
                          :color="row.nulled ? 'red' : 'teal'"
                          depressed
                          icon
                        >
                          <v-icon size="40">
                            {{
                              row.nulled
                                ? "mdi-close-box"
                                : "mdi-checkbox-marked"
                            }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td v-for="item in properties" :key="item.key">
                      <v-skeleton-loader type="table-cell@3" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer />
            <span v-if="recipe.pharmacyCode === 'SIN ASIGNAR'">
              La receta debe ser asignada antes de crear un reclamo
            </span>
            <v-btn
              v-else
              rounded
              outlined
              v-text="'Reclamo'"
              color="red darken-2"
              @click="claimDialog = true"
            />
            <v-spacer />
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ClaimDialog
      v-if="recipe"
      :showDialog="claimDialog"
      :recipeId="recipe.id"
      @closedialog="claimDialog = false"
    />

    <v-snackbar
      timeout="-1"
      top
      centered
      outlined
      multi-line
      color="red"
      rounded
      v-model="error.status"
    >
      <v-sheet color="white" class="pa-2 text-h6 text-center rounded20">
        <v-icon
          color="red"
          size="50"
          class="pb-1 pt-0"
          v-text="'mdi-information-outline'"
        />

        <v-card elevation="0" style="color: negative">
          <v-card-title> No encontramos la receta</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>- Corresponde al plan B2</v-list-item>
              <v-list-item>- No corresponde al período actual</v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-btn color="red" rounded outlined @click="error = {}">cerrar</v-btn>
      </v-sheet>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isNullOrEmpty } from "../../services/utils";
import { eventBus } from "../../services/eventBus.js";

import ClaimDialog from "../../components/claims/ClaimsDialogComponent.vue";

export default {
  components: {
    ClaimDialog,
  },

  name: "RecipeDetail",
  data: () => ({
    dialog: false,
    claimDialog: false,
    error: {},
    properties: [
      {
        key: "rp",
        title: "RP",
      },
      {
        key: "plan",
        title: "Plan",
      },
      {
        key: "quantity",
        title: "Cantidad",
      },
      {
        key: "orderedMed",
        title: "Med. Pedido",
      },
      {
        key: "deliveredMed",
        title: "Med. Entregado",
      },
      {
        key: "orderDate",
        title: "Pedido",
      },
      {
        key: "receptionDate",
        title: "Recepcion",
      },
      {
        key: "dispensationDate",
        title: "Dispensa",
      },
      {
        key: "status",
        title: "Estado",
      },
    ],
    recipeProperties: [
      {
        label: "Fecha de prescripcion",
        key: "prescDate",
        icon: "mdi-calendar",
      },
      {
        label: "Fecha de autorización",
        key: "authDate",
        icon: "mdi-calendar",
      },
    ],
    doctorProperties: [
      {
        key: "NombreYApellido",
        label: "Doctor",
        icon: "mdi-account",
      },
      {
        key: "Telefono",
        label: "Teléfono",
        icon: "mdi-phone",
      },
      {
        key: "Localidad",
        label: "Localidad",
        icon: "mdi-city",
      },
      {
        key: "Direccion",
        label: "Dirección",
        icon: "mdi-map-marker",
      },
    ],
  }),

  mounted() {
    eventBus.$on("recipeDetail", (id) => {
      this.fetchRecipe(id);
    });
  },
  methods: {
    ...mapActions("recipes", ["getRecipeIosper"]),

    isNullOrEmpty(val) {
      return isNullOrEmpty(val);
    },

    async fetchRecipe(id) {
      await this.getRecipeIosper(id).then((response) => {
        if (response.recipe) {
          this.dialog = true;
        } else {
          this.error = { status: true };
        }
      });
    },
  },

  computed: {
    ...mapGetters("recipes", ["recipe", "singleLoading"]),

    asigned: function () {
      return this.recipe ? this.recipe.pharmacyCode !== 0 : false;
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td {
  position: relative;
  padding: 5px 10px;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  width: 100%;
}
</style>
