<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="8">
        <v-text-field
          label="Código de receta"
          background-color="white"
          v-model="id"
          rounded
          filled
          hide-details
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-send"
          @click:append-outer="toDetail"
          @keyup.enter="toDetail"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" offset="2">
        <v-sheet class="pa-10 rounded20">
          <v-row>
            <v-spacer />
            <v-chip color="teal lighten-1" outlined dark label>
              <span class="overline">Búsquedas recientes</span>
            </v-chip>
            <v-spacer />
          </v-row>
          <template v-if="!iosperMovements.length && !loading">
            <v-row>
              <v-spacer />
              <span class="overline">AÚN NO SE HAN REALIZADO MOVIMIENTOSs</span>
              <v-spacer />
            </v-row>
          </template>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="item in properties"
                    :key="item.key"
                    class="text-center"
                  >
                    {{ item.title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading">
                  <template v-if="iosperMovements.length">
                    <tr
                      v-for="(row, index) in iosperMovements"
                      :key="index"
                      class="text-center"
                    >
                      <td v-for="item in properties" :key="item.key">
                        {{ item.prepend }} {{ row[item.key] }}
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td v-for="item in properties" :key="item.key">
                      <v-skeleton-loader type="table-cell@3" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-col>
    </v-row>
    <RecipeDetail />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import RecipeDetail from "../../components/iosper/RecipeDetail.vue";

import { eventBus } from "../../services/eventBus.js";

export default {
  components: {
    RecipeDetail,
  },
  data: () => ({
    id: null,
    properties: [
      {
        title: "Acción",
        key: "message",
      },
      {
        title: "Fecha",
        key: "date",
      },
    ],
  }),
  computed: {
    ...mapGetters("recipes", [
      "recipe",
      "iosperMovements",
      "loading",
      "singleLoading",
    ]),
  },
  mounted() {
    this.getMovementsIosperUser();
  },
  methods: {
    ...mapActions("recipes", ["getRecipeIosper", "getMovementsIosperUser"]),
    toDetail() {
      eventBus.$emit("recipeDetail", this.id);
      this.getMovementsIosperUser();
    },
  },
};
</script>